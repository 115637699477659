<template>
	<div class="app-main__inner" v-loading="loading">
		<div class="container mb-3 dm-sans-font">
			<div
				class="flex flex-col md:flex-row justify-between align-items-center mb-3"
			>
				<div>
					<div
						class="flex justify-between align-items-center space-x-3 mb-3"
					>
						<span
							class="text-sm md:text-2xl font-semibold text-gray-900"
							>{{ $t("viewProductDetail") }}</span
						>
					</div>
				</div>
				<div>
					<span
						class="text-base font-semibold text-blue-900 cursor-pointer"
						@click="$router.go(-1)"
						>{{ $t("back") }}</span
					>
				</div>
			</div>
			<div class="p-4 bg-white">
				<div class="row">
					<div class="col-md-4">
						<h3 style="font-size: 21px" class="mt-2 font-semibold">
							{{ currentArticle.description }}
						</h3>
						<br>
						<br>
						
						<div
							class="bg-white single-product-img mt-3 cursor-pointer"
							@click="showImages"
						>
							<el-carousel
								:interval="5000"
								indicator-position="none"
								v-if="currentArticle.images"
							>
								<el-carousel-item
									v-for="(item, i) in currentArticle.images"
									:key="i"
								>
									<span>
										<img
											class="carousel-cell-image"
											:src="item.image"
											data-zoomable=""
										/>
									</span>
								</el-carousel-item>
							</el-carousel>
						</div>
						<br>
						<br>
						<div class="flex justify-center mt-2">
							<button
							  @click="addProductCart"
							  class="focus:outline-none mr-5 px-12 py-1 flex justify-center btn-yellow rounded text-lg"
							  :disabled="prevNum < 1 && num < 1"
							>
							  {{ $t("buyProduct") }}
							</button>
							<input-number
							  :num="num"
							  @onchange="onChangeInput"
							/>
						  </div>
						  
						  
						  
					</div>
					<div class="col-md-5 mt- mb-10">
						<div class="bg-white flex flex-col space-y-9 p-6"> <!-- Added padding -->
						  <div
							v-if="
							  currentArticle.product_long_description !==
							  '<p>nan</p>'
							"
						  >
							<div
							  v-html="
								cleanData(
								  currentArticle.product_long_description
								)
							  "
							></div>
						  </div>
						</div>
					  </div>
					  
					  
					<div class="col-md-3 flex flex-col justify-between">
						<div class="flex flex-col space-y-3 bg-white mt-5">
							<div>
								
							</div>
							<p>
								{{ $t("articleNumber") }}:
								{{ currentArticle.article_number }}
							</p>
							<div
								class="flex space-x-4 items-center"
								v-if="
									GET_COMPANY_SETTING.hide_stock_quantity === false &&
									hidden_fields.stock_quantity === false
								"
								>
								<span v-if="currentArticle.allow_backorder_or_presale" class="preorder-item">
									{{ $t('preOrderItem') }}
								</span>
								<span v-else class="block">
									{{ $t('disposableQty') }}: {{ currentArticle.quantity_in_stock }}
								</span>
								</div>
							<br />
							<p
								class="italics"
								v-if="hidden_fields.delivery_time === false"
							>
								<i
									>{{ $t("Delivery") }}:
									{{ currentArticle.delivery_time }}
									{{ $t("days") }}</i
								>
							</p>
							<p
								class="italics"
								v-if="
									currentArticle.estimated_delivery_date !==
										'' &&
									hidden_fields.delivery_time === false
								"
							>
								<i
									>{{ $t("estimatedDeliveryDate") }}:
									{{
										currentArticle.estimated_delivery_date
									}}</i
								>
							</p>
							<br>
							<br>
							<div v-if="!currentArticle?.has_discount">
								<p class="price mt-2 text-black text-left ml-2">
								  {{ parseAmount(currentArticle.sales_price) }} {{ currentArticle.currency }}
								</p>
							  </div>
							  
							  
							<div v-else>
								<p class="price !text-red-600">
									{{ applyDiscount(
										parseAmount(currentArticle.sales_price),
										currentArticle?.discount_value,
										currentArticle?.discount_type === "PERCENT"
									) }}
									{{ currentArticle.currency }}
								</p>
							</div>
							<div v-if="currentArticle?.has_discount">
								<p class="priceSlash !text-black">
									<del>{{ parseAmount(currentArticle.sales_price) }} {{ currentArticle.currency }}</del>
								</p>

								<p
									class="priceDiscount !text-black"
									v-if="currentArticle?.has_discount"
								>
									{{ $t("discounted") }}
									<span
										class="font-black !text-red-500 text-[.8rem] font-bold"
									>
										{{
											convertDiscountValueToPercentage(
												currentArticle?.discount_value,
												currentArticle?.discount_type,
												currentArticle.sales_price
											)
										}}%</span
									>
								</p>
							</div>
							<div
								v-if="
									currentArticle.hasOwnProperty(
										'standard_price'
									)
								"
							>
								<div>
									<span class="font-semibold"
										>{{ $t("standard_price") }}:
										{{
											currentArticle.standard_price
										}}</span
									>
								</div>
							</div>
							<div
								style="margin-bottom: 12px"
								v-if="
									currentArticle.has_customer_resale_price &&
									hidden_fields.recommended_price === false
								"
							>
								<div>
									<span class="font-semibold">{{
										currentArticle.consumer_resale_field_title
									}}</span>
								</div>
								<div class="position-relative">
									<p
										class="customer-resale-price font-semibold text-blue-500"
									>
										{{currentArticle.consumer_resale_amount}}  {{currentArticle.currency}}
									</p>
								</div>
							</div>
							<div v-if="currentArticle.is_paired_product">
								<div class="m4">
									<span class="font-semibold"
										>{{ $t("productPackageText") }}:
										{{
											currentArticle.product_package_bundle_number
										}}</span
									>
								</div>
							</div>
							<div
								v-if="currentArticle.has_customer_resale_price"
							>
								<div class="">
									<span class="font-semibold"
										>{{ $t("profitMargin") }}:
										{{ calculateProfitMargin() }}</span
									>
								</div>
							</div>

							<div v-if="currentArticle.product_pdf" class="mt-3">
								<p class="text-base text-blue-600">
									<a
										:href="currentArticle.product_pdf"
										target="_blank"
										download
										class="flex"
										><img
											src="../../assets/images/icons/file.svg"
											class="cursor-pointer flex w-full"
											style="height: 18px; width: 18px"
											:alt="$t('productPdf')"
										/>
										<span>{{ $t("productPdf") }}</span>
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div v-if="GET_SINGLE_ARTICLE.specifications && GET_SINGLE_ARTICLE.specifications.length > 0" class="flex flex-col mt-5">
					<h2 class="text-xl font-semibold">
					  {{ $t("specification") }}
					</h2>
					<hr class="border-4 border-blue-900" />
					<div
					  :class="key % 2 === 1 ? 'bg-gray' : ''"
					  class="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-2 mt-2"
					  v-for="(item, key) in GET_SINGLE_ARTICLE.specifications"
					  :key="key"
					>
					  <p class="text-gray-500">{{ item.title }}</p>
					  <p class="text-gray-500">{{ item.text }}</p>
					</div>
				  </div>
				  

			<!-- Variations Section -->
			<div class="row mt-5" v-if="articleVariationList.length > 1">
				<div class="col-md-12">
				<div class="bg-white recommend-br">
					<div class="recommended-product-headline">
					<p class="headline-text">{{ $t("productVariations") }}</p>
					</div>
					
					<recommended-product
					:products="articleVariationList.slice(0, -1)"
					:user_type="'buyer'"
					/>
				</div>
				</div>
			</div>

			<!-- Recommended Products Section -->
			<div v-if="GET_SINGLE_ARTICLE.recommended_article && GET_SINGLE_ARTICLE.recommended_article.length > 0" class="row mt-5">
				<div class="col-md-12">
				<div class="bg-white recommend-br">
					<div class="recommended-product-headline">
					<p class="headline-text">{{ $t("recommendedProduct") }}</p>
					</div>
					<recommended-product
					:products="GET_SINGLE_ARTICLE.recommended_article"
					:user_type="'buyer'"
					/>
				</div>
				</div>
			</div>

			<!-- Accessories Section -->
			<div v-if="GET_SINGLE_ARTICLE.article_accessories && GET_SINGLE_ARTICLE.article_accessories.length > 0" class="row mt-5">
				<div class="col-md-12">
				<div class="bg-white recommend-br">
					<div class="recommended-product-headline">
					<p class="headline-text">{{ $t("accessories") }}</p>
					</div>
					<recommended-product
					:products="GET_SINGLE_ARTICLE.article_accessories"
					:user_type="'buyer'"
					/>
				</div>
				</div>
			</div>
			
 
  			</div>
		</div>
	</div>
</template>



<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.override-pt {
	padding-top: 1.2rem !important;
}

.override-pb {
	padding-bottom: unset !important;
}

.custom-rem {
	padding: 5rem;
}

.form-group {
	margin-bottom: 0.5rem !important;
}

.recommend-br {
	border: 1px solid #00000033;
	border-radius: 5px !important;
	padding: 10px;

	.el-carousel__container {
		height: 450px; // Increased height for larger images
	}

	.el-carousel__item {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.carousel-item-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%; // Ensure full width
		padding: 20px;
	}

	.image-container {
		width: 350px; // Increased width
		height: 350px; // Increased height
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	.carousel-image {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}

	.image-placeholder {
		width: 100%;
		height: 100%;
		background-color: #f0f0f0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #999;
	}

	.item-name {
		font-size: 18px; // Slightly larger font
		font-weight: bold;
		margin: 15px 0;
		text-align: center;
	}

	.item-price {
		font-size: 16px;
		color: #666;
	}
}

.el-carousel__container {
	height: 450px; // Match the height in .recommend-br
}

.total-summary {
	border-bottom: 1px solid #ddd;
	padding: 7px 0;
}

.el-loading-mask {
	.el-loading-spinner {
		display: unset !important;
	}
}

.el-check-out-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 9999999999999999999999;
}

.eid-cancel-btn {
	margin-top: 11px !important;
	padding: 6px !important;
}

.btn-yellow {
	border: 1px solid #e8b105;
	color: #e8b105;
	font-weight: bold;
}

.price {
	font-size: 30px;
	color: #413683;
	font-weight: 600;
	text-align: center;
	margin-top: 20px;
}

.priceSlash,
.priceDiscount {
	font-size: 20px;
	color: #413683;
	font-weight: 600;
	text-align: center;
}

.priceDiscount {
	font-size: 0.9rem;
	margin-bottom: 1rem;
}

.single-product-img {
    width: 100%; /* Ensure the container takes up the full width */
    height: auto; /* Allow the height to adjust automatically */
    overflow: hidden; /* Hide any overflow */
    padding: 0; /* Remove any padding inside the container */
    box-sizing: border-box; /* Include padding in the width/height calculations */
    text-align: center; /* Center the image horizontally */
}

.carousel-cell-image {
    width: 80%; /* Reduce the image size */
    height: auto; /* Maintain the aspect ratio */
    max-width: 80%; /* Prevent the image from exceeding 80% of the container's width */
    object-fit: contain; /* Ensure the image fits within the container without being cropped */
    display: block; /* Make sure the image is a block element */
    margin: 0 auto; /* Center the image within the container */
    padding: 0; /* Ensure no padding around the image */
}





 

.number-incremental {
	top: 6px;
}

.bg-gray {
	background: rgba(217, 217, 217, 0.2) !important;
}

.div-discount-price {
	margin-top: 0px;
	font-size: 18px;
	text-align: center;
}

.discounted-price {
	color: #495057;
	text-decoration: line-through;
}

.percentage-off {
	color: red;
}

.customer-resale-price {
	position: absolute;
	right: 153px;
	font-size: 18px;
}

.medium-zoom-image {
	cursor: pointer;
	cursor: zoom-in;
	position: absolute;
	transition: transform 0.3s cubic-bezier(0.2, 0, 0.2, 1) !important;
	z-index: 99;
}

.recommended-product-headline {
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 15px;
	font-weight: 500;
}

.preorder-item {
	background-color: #ffff00;
	color: #000000;
	padding: 5px 10px;
	border-radius: 4px;
	font-weight: bold;
	display: inline-block;
}

.recommended-product-headline > p {
	color: #4667a7 !important;
}

.headline-text {
	color: #4667a7 !important;
	text-align: left;
	font-weight: bold;
	margin-bottom: 10px;
}

.carousel-item-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.carousel-image {
	width: 350px; // Increased size
	height: 350px; // Increased size
	object-fit: contain; // Changed to contain to preserve aspect ratio
	border-radius: 8px;
}

.el-carousel__item {
	display: flex;
	justify-content: center;
	align-items: center;
}

.recommend-br {
	.el-carousel__container {
	  height: 500px !important; // Significantly increased height
	}
  
	.el-carousel__item {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	}
  
	.carousel-item-content {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  height: 100%;
	  width: 100%;
	}
  
	.image-container {
	  width: 400px; // Significantly increased width
	  height: 400px; // Significantly increased height
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  margin-bottom: 20px;
	}
  
	.carousel-image {
	  max-width: 100%;
	  max-height: 100%;
	  object-fit: contain;
	}
  
	.item-name {
	  font-size: 20px; // Larger font
	  font-weight: bold;
	  margin: 15px 0;
	  text-align: center;
	}
  
	.item-price {
	  font-size: 18px;
	  color: #666;
	}
  }
  
  // Override any conflicting styles
  .el-carousel__container {
	height: 500px !important;
  }
  
  .carousel-image {
	width: 400px !important;
	height: 400px !important;
	object-fit: contain !important;
  }
</style>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import RecommendedProduct from "./component/RecommendedProduct";
import productVariationList from "./component/productVariationComponents/productVariationList";

import inputNumber from "./component/inputNumber";
import { BUYER_HIDDEN_FIELDS } from "../../services/constant";
import {
	getUserSetting,
	getUserSettingHideFieldStatus,
} from "../../services/storage-window";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
import { convertDiscountValueToPercentage } from "../../utils/utils";

Vue.use(VueViewer);
export default {
	name: "checkout",
	components: {
		RecommendedProduct,
		productVariationList,
		inputNumber,
	},
	data() {
		return {
			convertDiscountValueToPercentage,
			delivery: {
				name: "",
				address: "",
				zipCode: "",
				city: "",
				country: "",
				email: "",
				reference: "",
				orderNumber: "",
				message: "",
			},
			bankIdNumber: "",
			loading: true,
			useBilling: false,
			processing: false,
			check: false,
			cancelBtn: false,
			openBankId: false,
			bankIDinfo: {},
			companyID: null,
			num: 0,
			prevNum: 0,
			price: 0,
			discounted: 0,
			percentOff: 0,
			currentArticle: {},
			articleVariationList: [],
			hidden_fields: {
				...JSON.parse(JSON.stringify(BUYER_HIDDEN_FIELDS)),
			},
			productImages: [],
		};
	},
	computed: {
		...mapGetters({
			GET_ARTICLES: "customer/GET_ARTICLES",
			CART_PRODUCT: "customer/CART_PRODUCT",
			CART_AMOUNT: "customer/CART_AMOUNT",
			GET_BILLING_INFO: "customer/GET_BILLING_INFO",
			GET_COUNTRY: "customer/GET_COUNTRY",
			GET_BANK_ID: "customer/GET_BANK_ID",
			CART_AMOUNT_INC_VAT: "customer/CART_AMOUNT_INC_VAT",
			CART_INC_VAT_AMOUNT: "customer/CART_INC_VAT_AMOUNT",
			GET_COMPANY_SETTING: "customer/GET_COMPANY_SETTINGS",
			GET_COMPANY: "customer/GET_COMPANY_SETTINGS",
			GET_AUTH_USER: "portal/GET_AUTH_USER",
			GET_SINGLE_ARTICLE: "customer/GET_SINGLE_ARTICLE",
			GET_ARTICLE_SPECIFICATION: "customer/GET_ARTICLE_SPECIFICATION",
			GET_ARTICLE_PARAMS: "customer/GET_ARTICLE_PARAMS",
			BUYER_SETTINGS: "customer/GET_USER_SETTINGS",
			HIDE_FIELD_STATUS: "customer/GET_USER_SETTINGS_HIDE_FIELD_STATUS",
		}),
		
	},
	watch: {
		num(newVal, oldVal) {
			this.prevNum = oldVal;
		},
	},
	beforeDestroy() {
		window.Bus.$off("get-bank-id");
	},
	mounted() {
		
		const { company_id, product_id } = this.$route.params;
		this.companyID = company_id;
		let pagenum = 1;
		if (this.GET_ARTICLE_PARAMS.pagenum !== "") {
			pagenum = this.GET_ARTICLE_PARAMS.pagenum;
		}
		let URL = `?company_id=${company_id}&limit=${this.GET_ARTICLE_PARAMS.limit}&page=${pagenum}`;
		if (this.GET_ARTICLE_PARAMS.searchname !== "") {
			URL += `&search=${this.GET_ARTICLE_PARAMS.searchname}`;
		}
		this.$store.dispatch("customer/getArticles", { pageUrl: URL });
		this.getCompanyInfo(company_id);
		this.getArticles();
		if (company_id === "invalid_company") {
			this.$services.helpers.notification(
				this.$t("no_company_text"),
				"error",
				this
			);
		}
		window.Bus.$on("revert-input-to-zero", (e) => {
			this.num = 0;
		});
		window.Bus.$on("set-current-variation", (e) => {
			this.setCurrentArticle(e);
		});

		window.Bus.$on("trigger-hidden-field-event", (e) => {
			this.disabledFields(e);
		});
	},
	beforeRouteUpdate(to, from, next) {
		// react to route changes...
		// don't forget to call next()
		next();
	},
	methods: {
		applyDiscount(price, discount, isPercent = true) {
			if (isPercent) {
				let discountAmount = price * (discount / 100);
				let discountedPrice = price - discountAmount;
				return discountedPrice.toFixed(2);
			} else {
				return Number(price) - Number(discount);
			}
		},
		showImages() {
			if (this.productImages.length > 0) {
				this.$viewerApi({
					images: this.productImages,
				});
			}
		},
		disabledFields: function (status) {
			if (getUserSetting()) {
				if (status === true) {
					let fields = getUserSetting();
					let hidden_fields = fields.hidden_fields;
					let visible_fields = fields.visible_fields;
					visible_fields.map((item) => {
						this.hidden_fields[item] = false;
					});
					hidden_fields.map((item) => {
						this.hidden_fields[item] = true;
					});
				} else {
					Object.keys(this.hidden_fields).map((item) => {
						this.hidden_fields[item] = false;
					});
				}
			}
		},
		setCurrentArticle(article) {
			this.currentArticle = article;
			
		},
		parseAmount(amount) {
			let am = String(amount).split(".");
			if (am.length > 0) {
				if (parseFloat(am[1]) === 0.0) {
					return am[0];
				} else {
					return amount;
				}
			} else {
				return amount;
			}
		},
		getCompanyInfo(id) {
			this.companyID = id;
			const payload = {
				id: id,
			};
			this.$store.dispatch("customer/getCompanySettings", payload);
		},
		getArticles() {
			this.loading = true;
			this.$store
			
				.dispatch("customer/getSingleArticles", {
					pageUrl: `/${this.$route.params.product_id}/?company_id=${this.companyID}`,
				})
				.then(() => {
					this.loading = false;
					this.currentArticle = this.GET_SINGLE_ARTICLE;

					if (this.currentArticle.article_variations.length > 0) {
						

						this.articleVariationList = this.currentArticle.article_variations;
						
						this.currentArticle.article_variations.push(
							this.currentArticle.id
						);
						this.articleVariationList = [
							...new Set(this.currentArticle.article_variations),
						];
					}
					this.productImages = [];
					if (this.GET_SINGLE_ARTICLE.images.length > 0) {
						this.GET_SINGLE_ARTICLE.images.map((item) => {
							this.productImages.push(item.image);
						});
					}
					console.log('Article Variations:', this.articleVariationList);
						console.log('First Variation Images:', this.articleVariationList[0].images);
					this.disabledFields(getUserSettingHideFieldStatus());
				})
				.catch((err) => {
					this.loading = false;
					this.$store.commit("customer/UPDATE_SINGLE_ARTICLE", {});
					
				});
		},
		onChangeInput(mode) {
			this.num = mode;
		},
		
		addProductCart() {
			let qty = this.num || 1;  // Default quantity to 1 if num is not set
			let currentDate = moment().format("YYYY-MM-DD");
			let productLoad = {
				product: this.currentArticle,
				qty,
				apply_discount: false,
			};

			
			
			// Check stock threshold and notify if out of stock
			if (this.GET_COMPANY.apply_stock_threshold === true && !this.currentArticle.allow_backorder_or_presale) {
				if (parseFloat(this.currentArticle.quantity_in_stock) <= parseFloat(this.GET_COMPANY.threshold_product_visibility)) {
					this.$services.helpers.notification(this.$t('outOfStock'), 'error', this);
					return false;
				}
			}

			if (!this.currentArticle.allow_backorder_or_presale && parseFloat(this.currentArticle.quantity_in_stock) <= 0) {
				this.$services.helpers.notification(this.$t('outOfStock'), 'error', this);
				return false;
			}

			const totalPrice = parseFloat(this.currentArticle.sales_price * qty);

			// Minimum order quantity check
			if (this.currentArticle.minimum_order_quantity && qty < this.currentArticle.minimum_order_quantity) {
				return this.$services.helpers.notification(
					`${this.$t("customerMininmumOrderQtyError")} ${this.currentArticle.minimum_order_quantity}`,
					"error",
					this
				);
			}

			// Minimum order amount check
			if (this.currentArticle.minimum_order_amount && totalPrice < this.currentArticle.minimum_order_amount) {
				return this.$services.helpers.notification(
					`${this.$t("customerMinimumOrderAmountError")} ${this.currentArticle.minimum_order_amount}`,
					"error",
					this
				);
			}

			// Check and apply discount if within scheduled campaign dates
			if (this.currentArticle.discounted_price > 0) {
				if (this.currentArticle.scheduled_campaign_start_date && this.currentArticle.scheduled_campaign_end_date) {
					let startDate = moment(this.currentArticle.scheduled_campaign_start_date).format("YYYY-MM-DD");
					let endDate = moment(this.currentArticle.scheduled_campaign_end_date).format("YYYY-MM-DD");
					if (currentDate >= startDate && currentDate <= endDate) {
						productLoad.apply_discount = true;
					}
				}
			}

			// Emit custom row text event if allowed
			if (this.currentArticle.allow_custom_row_text === true) {
				window.Bus.$emit("open-custom-row-text", { data: this.currentArticle, qty });
			}

			// Add product to cart if quantity is valid
			if (productLoad.qty) {
				console.log('Committing product to cart with quantity:', productLoad.qty);
				this.$store.commit("customer/ADD_CART_PRODUCT", productLoad);
			} else {
				console.warn('Quantity is invalid, not adding to cart.');
			}

			// Emit success message with item description
			window.Bus.$emit("message", {
				data: `${qty} ${this.$t("itemAdd") || 'item(s) added to cart'}`,
			});

			// Reset input field to zero
			window.Bus.$emit("revert-input-to-zero");
		},



		handleChange(e) {
			if (e === undefined) {
				this.num = 0;
			} else {
				this.num = e;
			}
		},
		hasDiscount() {
			let currentDate = moment().format("YYYY-MM-DD");
			if (
				this.GET_SINGLE_ARTICLE.scheduled_campaign_start_date !==
					null &&
				this.GET_SINGLE_ARTICLE.scheduled_campaign_end_date !== null &&
				this.GET_SINGLE_ARTICLE.scheduled_campaign_start_date !== "" &&
				this.GET_SINGLE_ARTICLE.scheduled_campaign_end_date !== ""
			) {
				let startDate = moment(
					this.GET_SINGLE_ARTICLE.scheduled_campaign_start_date
				).format("YYYY-MM-DD");
				let endDate = moment(
					this.GET_SINGLE_ARTICLE.scheduled_campaign_end_date
				).format("YYYY-MM-DD");
				if (currentDate >= startDate && currentDate <= endDate) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
		getPercentage() {
			let discountedPrice =
				parseFloat(this.GET_SINGLE_ARTICLE.sales_price) -
				parseFloat(this.GET_SINGLE_ARTICLE.discounted_price);
			let total =
				(discountedPrice /
					parseFloat(this.GET_SINGLE_ARTICLE.sales_price)) *
				100;
			let percentile = Math.ceil(total);
			if (percentile) {
				return percentile;
			} else {
				return "";
			}
		},
		cleanData: function (name) {
			if (["nan"].includes(name)) {
				return "";
			} else {
				return name;
			}
		},
		calculateProfitMargin() {
			let net_revenue =
				parseFloat(this.GET_SINGLE_ARTICLE.consumer_resale_amount) -
				parseFloat(this.GET_SINGLE_ARTICLE.sales_price);
			let profit = parseFloat(
				(parseFloat(net_revenue) /
					parseInt(this.GET_SINGLE_ARTICLE.consumer_resale_amount)) *
					100
			).toFixed(2);
			return profit;
		},
	},
};
</script>

