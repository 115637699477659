<template>
	<flickity ref="flickity" :options="flickityOptions">
		<variations v-for="(i,key) in products" :key="`key_${key}`" :product_id="i"
								  :user_type="user_type"/>
	</flickity>
</template>

<script>
import Flickity from "vue-flickity";
import variations from "./../../component/productVariationComponents/variations";
export default {
	name: "productVariationList",
	components: {
		Flickity,
		variations,
	},
	props: {
		products: {
			type: Array,
			required: true
		},
		user_type: {
			type: String,
			default: 'buyer'
		}
	},
	data: function () {
		return {
			flickityOptions: {
				initialIndex: 1,
				prevNextButtons: true,
				pageDots: false,
				wrapAround: true,
				groupCells: true,
				autoPlay: true,
				lazyLoad: 5,
				cellAlign: 'left',
				contain: true,
				width: '25%'
			},
		}
	}
}
</script>
<style scoped>
.carousel.is-fullscreen .carousel-cell {
	height: 100%;
}

.carousel-cell img {
	display: block;
	max-height: 100%;
}

</style>
